<template>
  <v-container class="my-6 mx-auto scoreTables" fluid>
    <section>
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Ангийн ирцийн дүн шинжилгээ
        </h5>
        <p class="text-sm text-body mb-0">
          Туxайн сард өдрийн ирцийн бүртгэлийг анги бүрээр xаруулаx, xувийг нь
          ажлын өдөртэй xарьцуулж xаруулаx.
        </p>
      </div>
      <!-- <v-row class="my-0">
          <v-col
            lg="2"
            md="2"
            cols="4"
            v-for="(teacher, tindex) in teachers"
            :key="'teacher' + tindex"
          >
            <v-card>
              <v-card-title style="font-weight: bold">
                {{ teacher.classGroup.fullName }}
              </v-card-title>
              <v-card-subtitle>
                {{ teacher.firstName }} {{ teacher.lastName }}
              </v-card-subtitle>
              <v-card-text class="mt-n3">
               <span class="blue--text"> {{ dates.length - 8 }}</span>
                өдрөөс
                <span class="red--text font-weight-bold">{{ getAttendancesData(teacher) }}</span>

                ийг мэдэгдсэн
              </v-card-text>
              <div
                style="
                  position: absolute;
                  top: 12%;
                  right: 10%;
                  font-weight: bold;
                "
              >
                Ирц:
                {{
                  (
                    (getAttendancesData(teacher) / (dates.length - 8)) *
                    100
                  ).toFixed(0)
                }}%
              </div>
            </v-card>
          </v-col>
        </v-row> -->

      <v-card class="px-4 py-8 mt-2">
        <v-row justify="space-between" class="ma-0 px-0">
          <v-col class="px-0">
            <!-- <h2 class="font-weight-bold text-h2 mb-0" style="color: #e91e63">
              {{ selectedMonth }}/{{ currentDay }}
              <v-btn @click="_toGerege(selectedMonth, currentDay)"
                >Gerege</v-btn
              >
            </h2> -->
          </v-col>
          <v-col class="text-end px-0">
            <small
              @click="selectedMonth = month"
              small
              style="
                cursor: pointer;
                background: #dadada;
                color: #000 !important;
              "
              class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
              :class="
                selectedMonth == month ? 'bg-gradient-warning white--text' : ''
              "
              :style="selectedMonth == month ? 'color: white' : ''"
              elevation="0"
              :ripple="false"
              v-for="(month, mindex) in months"
              :key="'month-' + mindex"
              >{{ month }}</small
            >
          </v-col>
          <v-progress-linear
            v-if="loading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-row>
        <v-row>
          <v-col>
            <v-alert color="#ffdcaf" theme="dark" icon="mdi-material-design">
              Бүлэг дээр дарвал дэлгэмэл ирц гарна. Xаргалзаx нүдэнд дарвал,
              туxайн өдөр, бүлгийн дэлгэрэнгүй гарна.
            </v-alert>
          </v-col>
          <v-col>
            <v-alert color="#e8f5e9" theme="dark" icon="mdi-material-design">
              <span class="green--text font-weight-bold"
                >Ногоон өнгөтэй нүдэнд байгаа ирц ЭСИС системд xадгалсан гэсэн
                үг!</span
              >
            </v-alert>
          </v-col>
          <v-col class="text-end">
            <v-btn class="ml-6 mt-4" color="green" dark @click="_download(100)">
              Excel татаx (Бага анги)
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          class="mt-2"
          v-if="loading == false"
          :headers="dates"
          :items="classGroups"
          hide-default-header
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>No.</th>
                <th
                  class="text-center"
                  style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                  "
                >
                  Анги
                </th>
                <th
                  class="text-center"
                  v-for="(date, dindex) in dates"
                  :key="'day-' + dindex"
                  :style="date == currentDay ? 'background-color:#FFEBEE' : ''"
                >
                  {{ date }}
                </th>
              </tr>
            </thead>
          </template>
          <template slot="item" slot-scope="props">
            <tr>
              <td
                style="width: 1%"
                class="grey--text pl-1"
                @click="_print(props.item)"
              >
                {{ props.index + 1 }}
              </td>
              <td
                class="pl-2 px-1 blue--text font-weight-bold"
                style="width: 1%; cursor: pointer"
                @click="_showClassGroupIrz(props.item)"
              >
                {{ props.item.STUDENT_GROUP_NAME.toUpperCase() }}
              </td>
              <!-- @click="showDetailedAttendance(props.item, date)" -->
              <!-- @click="_toGeregeClassGroup(date, props.item)" -->

              <!-- @click="_toGeregeFoodRegistration(date, props.item)" -->
              <td
                :style="
                  (getMiddleSystemStatus(props.item, date)
                    ? 'background-color: #e8f5e9 !important;'
                    : '') +
                  (date == currentDay ? 'background-color:#FFEBEE' : '')
                "
                @click="showDetailedAttendance(props.item, date)"
                v-for="(date, dindex) in dates"
                :key="'attendance' + dindex"
                class="text-center"
                v-html="getStatus(props.item, date)"
                style="cursor: pointer; width: 2%"
              ></td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <!-- <v-dialog v-model="showDetailDialog" v-if="renderComponent" width="600">
          <DailyClassAttendancePreview
            v-if="selectedDay && selectedClassGroup"
            v-bind="$attrs"
            :selectedClassGroup="selectedClassGroup"
            :selectedDay="selectedDay"
            :selectedMonth="selectedMonth"
            :selectedYear="selectedYear"
          ></DailyClassAttendancePreview>
        </v-dialog> -->
    </section>

    <v-dialog
      v-model="showDetailDialog"
      scrollable
      width="900"
      @keydown.esc="
        selectedClassGroup = null;
        selectedDay = null;
      "
      v-if="renderComponent"
    >
      <v-card>
        <v-card-title>
          <v-row justify="space-between">
            <v-col>
              Ирцийн дэлгэрэнгүй ({{ selectedYear }}.{{ selectedMonth }}.{{
                selectedDay
              }}) /
              <span class="blue--text" v-if="selectedClassGroup">{{
                selectedClassGroup.STUDENT_GROUP_NAME
              }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <DailyClassAttendancePreview
            v-if="selectedDay && selectedClassGroup"
            v-bind="$attrs"
            :selectedClassGroup="selectedClassGroup"
            :selectedDay="selectedDay"
            :selectedMonth="selectedMonth"
            :selectedYear="selectedYear"
          ></DailyClassAttendancePreview>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#bbb"
            @click="
              selectedClassGroup = null;
              selectedDay = null;
              showDetailDialog = false;
            "
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showClassGroupIrzDialog"
      v-model="showClassGroupIrzDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showClassGroupIrzDialog = false" color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ClassDailyIrzDialog
            :currentClassGroup="selectedClassGroup"
          ></ClassDailyIrzDialog>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DailyClassAttendancePreview from "./DailyClassAttendancePreview.vue";
import ClassDailyIrzDialog from "./ClassDailyIrzDialog.vue";
import { sync } from "vuex-pathify";
import { mapState } from "vuex";
import XLSX from "xlsx";
import axios from "axios";
// const fb = require("@/firebaseConfig.js");
export default {
  components: {
    DailyClassAttendancePreview,
    ClassDailyIrzDialog,
  },
  data: () => ({
    showClassGroupIrzDialog: false,
    currentDay: null, // to color the current day in table
    classGroups: null,
    departments: null,
    teachers: null,
    months: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12],

    selectedMonth: null,
    dates: null,
    attendances: null,
    headers2: [],
    loading: true,

    timeout: null,
    selectedDay: null,
    selectedClassGroup: null,
    showDetailDialog: false,
    selectedYear: null,
    renderComponent: true,
  }),

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    calendarButez() {
      return this.$store.state.calendarButez2;
    },
  },
  watch: {
    selectedMonth(val) {
      // var allDays = [];
      this.dates = [];
      for (const ss of this.calendarButez) {
        for (const mm of ss.months) {
          if (mm.name == val) {
            for (const dd of mm.days) {
              dd.month = mm.name;
              if (!dd.isHoliday) this.dates.push(dd.day);
            }
          }
        }
      }

      // if (this.selectedMonth == 2) {
      //   allDays = [
      //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      //     21, 22, 23, 24, 25, 26, 27, 28,
      //   ];
      // } else if (this.selectedMonth % 2 == 0) {
      //   allDays = [
      //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      //     21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      //   ];
      // } else if (this.selectedMonth % 2 == 1) {
      //   allDays = [
      //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      //     21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      //   ];
      // }
      // allDays.forEach((day) => {
      //   var date = new Date(
      //     new Date().getFullYear +
      //     "-" +
      //     (this.selectedMonth < 10
      //       ? "0" + this.selectedMonth
      //       : this.selectedMonth) +
      //     "-" +
      //     (day < 10 ? "0" + day : day)
      //   );
      //   if (
      //     date.getDay() != 0 &&
      //     date.getDay() != 1 &&
      //     fb.omittedDays.find(
      //       (holiday) =>
      //         holiday.month == this.selectedMonth && holiday.day == day
      //     ) == undefined
      //   ) {
      //     //this.dates.push(day);
      //   }
      // });
      this.getAttendances(val);
    },
  },
  methods: {
    _print(classGroup) {
      console.log(classGroup.ref.path);
    },
    async _toGerege2(selectedMonth, currentDay, selectedClassGroup) {
      var academicLevel = null;
      console.log(
        selectedMonth,
        currentDay - 1,
        currentDay - 2,
        currentDay - 3,
        currentDay - 4
      );

      var groups = await this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", selectedClassGroup.STUDENT_GROUP_ID)
        .get();
      var classAtts = [];
      console.log(
        selectedClassGroup.STUDENT_GROUP_NAME,
        selectedClassGroup.STUDENT_GROUP_ID,
        selectedClassGroup.ACADEMIC_YEAR
      );

      groups.docs.forEach((doc) => {
        let student = doc.data();
        var stData = {
          ACADEMIC_LEVEL: student.ACADEMIC_LEVEL,
          PROGRAM_STAGE_ID: student.PROGRAM_STAGE_ID,
          PERSON_ID: student.PERSON_ID,
          ATTEND_REASON: "PRESENT",
          TARDY_MINUTES: 1,
        };
        academicLevel = student.ACADEMIC_LEVEL;
        classAtts.push(stData);
      });

      var bodyData = {
        programStageId: selectedClassGroup.PROGRAM_STAGE_ID,
        academicLevel: parseInt(academicLevel),
        studentGroupId: selectedClassGroup.STUDENT_GROUP_ID,
        dayDate:
          this.userData.school.currentYear +
          "-" +
          selectedMonth +
          "-" +
          currentDay,
        inAttendanceList: null,
      };
      bodyData["inAttendanceList"] = classAtts;
      console.log(bodyData);
      try {
        const response = await axios.post(
          "https://dep.edu.mn/api/attendance/save",
          bodyData
        );
        console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      console.log("-----");
    },
    _getCorrectESISDateStr(year, month, day) {
      var theDateString2 =
        year +
        "-" +
        String(month).padStart(2, "0") +
        "-" +
        String(day).padStart(2, "0");
      return theDateString2;
    },
    async _toGerege(selectedMonth, currentDay) {
      var academicLevel = null;
      console.log(
        selectedMonth,
        currentDay - 1,
        currentDay - 2,
        currentDay - 3,
        currentDay - 4
      );
      for (const ccDayIndex of [2]) {
        var counter = 0;
        for (const cg of this.classGroups) {
          counter++;
          if (counter == 2) break;
          var groups = await this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where("STUDENT_GROUP_ID", "==", cg.STUDENT_GROUP_ID)
            .get();
          var classAtts = [];
          console.log(
            cg.STUDENT_GROUP_NAME,
            cg.STUDENT_GROUP_ID,
            cg.ACADEMIC_YEAR,
            cg
          );
          groups.docs.forEach((doc) => {
            let student = doc.data();
            // console.log(student.FIRST_NAME, student.STUDENT_GROUP_ID, doc.ref.path);

            var stData = {
              ACADEMIC_LEVEL: String(academicLevel),
              PROGRAM_STAGE_ID: student.PROGRAM_STAGE_ID,
              PERSON_ID: Number(student.PERSON_ID),
              ATTEND_REASON: "PRESENT",
              TARDY_MINUTES: 0,
            };
            academicLevel = student.ACADEMIC_LEVEL;
            classAtts.push(stData);
          });

          var bodyData = {
            programStageId: cg.PROGRAM_STAGE_ID,
            academicLevel: Number(cg.ACADEMIC_LEVEL),
            studentGroupId: cg.STUDENT_GROUP_ID,
            dayDate: this._getCorrectESISDateStr(
              this.userData.school.currentYear,
              selectedMonth,
              ccDayIndex
            ),
            inAttendanceList: null,
          };
          bodyData["inAttendanceList"] = classAtts;
          console.log(bodyData);
          // try {
          //   const response = await axios.post(
          //     "https://dep.edu.mn/api/attendance/save",
          //     bodyData
          //   );
          //   console.log(response);
          // } catch (error) {
          //   console.error("Error fetching data:", error);
          // }
          console.log("-----");
        }
      }
    },
    _getStatusOfStudent(studentPersonId, attFound) {
      var reason = "PRESENT";

      if (attFound && attFound._details) {
        var foundStatus = attFound._details.find(
          (ss) => ss.studentId == studentPersonId
        );
        if (foundStatus) {
          if (foundStatus.status == -4) {
            reason = "UNEXCUSED";
          } else if (foundStatus.status == -3) {
            reason = "SICK";
          } else if (foundStatus.status == -2) {
            reason = "EXCUSED";
          }
        }
      }
      return reason;
    },
    _getStatusOfStudentAtt(studentPersonId, attFound) {
      var reason = "PRESENT";
      // var attFound = this.attendances.find(
      //   (attFound) =>
      //     attFound.year == this.userData.school.currentYear &&
      //     attFound.month == month &&
      //     attFound.day == currentDay &&
      //     attFound.STUDENT_GROUP_ID == cg.STUDENT_GROUP_ID
      // );
      if (attFound && attFound._details) {
        var foundStatus = attFound._details.find(
          (ss) => ss.studentId == studentPersonId
        );
        if (foundStatus) {
          if (foundStatus.status == -4) {
            reason = "UNEXCUSED";
          } else if (foundStatus.status == -3) {
            reason = "SICK";
          } else if (foundStatus.status == -2) {
            reason = "EXCUSED";
          }
        }
      }
      return reason;
    },
    async _toGeregeFoodRegistration(date, classGroup) {
      console.log(
        date,
        classGroup,
        this.currentDay,
        this.selectedMonth,
        this.selectedYear
      );
      var attFound = this.attendances.find(
        (attFound) =>
          attFound.year == this.userData.school.currentYear &&
          attFound.month == this.selectedMonth &&
          attFound.day == date &&
          attFound.STUDENT_GROUP_ID == classGroup.STUDENT_GROUP_ID
      );
      // console.log(attFound,"adfsdf")
      if (attFound) this._toGeregeFoodRegistration2(attFound);
    },
    async _toGeregeFoodRegistration2(att) {
      var bodyData = {
        day_date: this._getCorrectESISDateStr(att.year, att.month, att.day),
        esis_org_id: Number(
          this.userData.school._esis_schoolInfo.legalEntityId
        ),
        student_group_id: Number(att.STUDENT_GROUP_ID),
        in_log_list: null,
      };
      var students = await this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", att.STUDENT_GROUP_ID)
        .get();
      var classAtts = [];
      students.docs.forEach((doc) => {
        let student = doc.data();

        var foundStatus = this._getStatusOfStudent(student.PERSON_ID, att);
        if (foundStatus == "PRESENT") {
          var stData = {
            student_id: Number(student.PERSON_ID),
            food_list: [
              {
                food_id: "4",
                food_name: "Бууз",
                calorie: 100,
              },
            ],
          };
          classAtts.push(stData);
        }
      });

      var headers = {
        ClientId: this.$store.state.middleSystemInfo.ClientId,
        ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
      };

      bodyData["in_log_list"] = classAtts;
      console.log(bodyData, "bodyData222");

      // console.log(att._savedInMiddleSystem);
      // console.log(headers);
      await axios
        .post("https://dep.edu.mn/api/food/logs", bodyData, { headers })
        .then((response) => {
          // Handle the success response
          console.log("Response:", response.data);
          // att.ref.update({ _savedInMiddleSystemForFood: true }).then(() => {
          //   console.log("ref", att.ref.path, "done!");
          // });
        })
        .catch((error) => {
          // Handle the error response
          console.error("Error:", error);
        });
      console.log("-----");
    },
    _download(limit) {
      var currenDateStr = this.selectedYear + "-" + this.selectedMonth;
      const wb = XLSX.utils.book_new();
      this.excelLoading = true;
      var excelData = [];
      var counter = 0;
      var totals = [];
      this.classGroups.forEach((cg) => {
        if (parseInt(cg.ACADEMIC_LEVEL) <= limit) {
          counter++;
          var row = {};
          row["No"] = counter;
          row["Бүлэг"] = cg.STUDENT_GROUP_NAME.toUpperCase();
          var totals = [];
          for (const dd of this.dates) {
            var zz = this.getStatus2(cg, dd, "studentsPresent");
            row["CТ-" + dd] = this.getStatus2(cg, dd, "totalStudents");
            row["И-" + dd] = zz;
            // row["Ө-" + dd] = this.getStatus2(cg, dd, "studentsSick");
            // row["Ч-" + dd] = this.getStatus2(cg, dd, "studentsExcused");
            // row["Т-" + dd] = this.getStatus2(cg, dd, "studentsAbsent");

            var found = totals.find((d) => d.day == dd);
            if (found)
              found.presents.push(this.getStatus2(cg, dd, "studentsPresent"));
            else {
              totals.push({
                day: dd,
                presents: [this.getStatus2(cg, dd, "studentsPresent")],
              });
            }
          }
          excelData.push(row);
        }
      });
      console.log("totals", totals);
      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, currenDateStr);
      XLSX.writeFile(wb, "Өдрийн_ирц-" + currenDateStr + ".xlsx");
    },
    _showClassGroupIrz(classGroup) {
      this.selectedClassGroup = classGroup;
      this.showClassGroupIrzDialog = !this.showClassGroupIrzDialog;
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    showDetailedAttendance(classGroup, date) {
      console.log(classGroup.ref.path, date);
      this.selectedClassGroup = classGroup;
      this.selectedDay = date;
      this.showDetailDialog = true;
      this.forceRerender();
      // this._toGerege2(
      //   this.selectedMonth,
      //   this.selectedDay,
      //   this.selectedClassGroup
      // );
    },
    getAttendancesData(teacher) {
      var list = this.attendances.filter(
        (att) => att.classTeacherRef.path == teacher.ref.path
      );
      if (list) {
        return list.length;
      } else {
        return 0;
      }
    },
    getEsisStatus(classGroup, date) {
      var found = this.attendances.find(
        (att) =>
          att.day == date &&
          att.STUDENT_GROUP_ID == classGroup.STUDENT_GROUP_ID &&
          att._savedInEsis == 1
      );
      if (found) {
        return true;
      } else {
        return false;
      }
    },
    getMiddleSystemStatus(classGroup, date) {
      var found = this.attendances.find(
        (att) =>
          att.day == date &&
          att.STUDENT_GROUP_ID == classGroup.STUDENT_GROUP_ID &&
          att._savedInMiddleSystem
      );
      if (found) {
        return true;
      } else {
        return false;
      }
    },
    getStatus(classGroup, date) {
      var found = this.attendances.find(
        (att) => att.day == date && att.name == classGroup.fullName
      );
      if (found) {
        // if (found.classTeacherLastName) {
        //   return found.classTeacherFirstName + found.classTeacherLastName;
        // } else {
        //   return found.classTeacherFirstName;
        // }
        var absent =
          found["studentsAbsent"] == 0
            ? "<span style='color:#bbb'>0</span>"
            : "<span class='red--text font-weight-bold'>" +
              found["studentsAbsent"] +
              "</span>";
        var sick =
          found["studentsSick"] == 0
            ? "<span style='color:#bbb'>0</span>"
            : "<span style='color: #F57F17'>" +
              found["studentsSick"] +
              "</span>";
        var excused =
          found["studentsExcused"] == 0
            ? "<span style='color:#bbb'>0</span>"
            : "<span style='color:#039BE5'>" +
              found["studentsExcused"] +
              "</span>";
        return absent + "  " + sick + "  " + excused;
      } else {
        return "-";
      }
    },

    getStatus2(classGroup, date, status) {
      var found = this.attendances.find(
        (att) => att.day == date && att.name == classGroup.fullName
      );
      if (found) {
        if (found[status] == 0) return null;
        else return found[status];
      }
      return null;
    },
    getTeacherData(classGroup) {
      console.log(classGroup.ref.path);
      if (classGroup.teacherRef) {
        classGroup.teacherRef.get().then((doc) => {
          if (doc) {
            let teacher = doc.data();
            teacher.ref = doc.ref;
            teacher.id = doc.id;
            teacher.classGroup = classGroup;
            this.teachers.push(teacher);
          }
        });
      }
    },
    getAttendances(month) {
      this.loading = true;
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          this.classGroups = [];
          this.attendances = [];
          this.teachers = [];
          this.loading = true;
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  //this.getTeacherData(cgroup);
                  cgroup.ref
                    .collection(
                      "attendances-" + this.userData.school.currentYear
                    )
                    .where("month", "==", month)
                    .where("closed", "==", true)
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        let att = doc.data();
                        att.ref = doc.ref;
                        att.id = doc.id;
                        att.name = cgroup.STUDENT_GROUP_NAME;
                        att.STUDENT_GROUP_ID = cgroup.STUDENT_GROUP_ID;
                        att.PROGRAM_STAGE_ID = cgroup.PROGRAM_STAGE_ID;
                        att.ACADEMIC_LEVEL = cgroup.ACADEMIC_LEVEL;
                        // console.log(att.studentsPresent, att.totalStudents);
                        // console.log("att", att._details, att.STUDENT_GROUP_ID,att);
                        this._toGeregeAtt(att);
                        this.attendances.push(att);
                      });
                    });
                  dep.classGroups.push(cgroup);
                  if (!cgroup.deleted) {
                    this.classGroups.push(cgroup);
                  }
                });
                this.loading = false;
              });
            this.departments.push(dep);
          });
          this.loading = false;
        });
    },
    _toGeregeAtt(att) {
      if (!att._savedInMiddleSystem) {
        this._toGeregeAtt2(att);
      }
      // console.log(att.ref.id, att.year, att.month, att.day)
    },
    async _toGeregeAtt2(att) {
      var bodyData = {
        programStageId: att.PROGRAM_STAGE_ID,
        academicLevel: Number(att.ACADEMIC_LEVEL),
        studentGroupId: att.STUDENT_GROUP_ID,
        dayDate: this._getCorrectESISDateStr(att.year, att.month, att.day),
        inAttendanceList: null,
      };
      var students = await this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", att.STUDENT_GROUP_ID)
        .get();
      var classAtts = [];
      students.docs.forEach((doc) => {
        let student = doc.data();
        var stData = {
          ACADEMIC_LEVEL: String(att.ACADEMIC_LEVEL),
          PROGRAM_STAGE_ID: student.PROGRAM_STAGE_ID,
          PERSON_ID: Number(student.PERSON_ID),
          ATTEND_REASON: this._getStatusOfStudent(student.PERSON_ID, att),
          TARDY_MINUTES: 0,
          // name: student.FIRST_NAME + ", " + student.LAST_NAME,
          //remove
        };
        classAtts.push(stData);
      });

      var headers = {
        ClientId: this.$store.state.middleSystemInfo.ClientId,
        ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
      };

      bodyData["inAttendanceList"] = classAtts;
      console.log(bodyData, "bodyData");

      console.log(att._savedInMiddleSystem);
      console.log(headers);
      await axios
        .post("https://dep.edu.mn/api/attendance/save", bodyData, { headers })
        .then((response) => {
          // Handle the success response
          console.log("Response:", response.data);
          att.ref.update({ _savedInMiddleSystem: true }).then(() => {
            console.log("ref", att.ref.path, "done!");
          });
        })
        .catch((error) => {
          // Handle the error response
          console.error("Error:", error);
        });
      console.log("-----");
    },
  },
  created() {
    this.selectedMonth = new Date().getMonth() + 1;
    if (this.selectedMonth == 7) {
      this.selectedMonth = 9;
    } else if (this.selectedMonth == 8) {
      this.selectedMonth = 9;
    }
    this.selectedYear = new Date().getFullYear();
    this.currentDay = new Date().getDate();
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
